<template>
  <layout :class="['root', themeStyles?.app?.root]">
    <Progress class="progress" :percent="progress" :show-info="false" />

    <div class="container">
      <layout-header :class="['header', themeStyles?.header?.header]">
        <div :class="['header-content']">
          <span :class="['header-icon', themeStyles?.header?.icon]">
            <template v-if="theme?.icons?.logo">
              <component :is="theme.icons.logo" />
            </template>

            <template v-else>
              <logo-icon />
            </template>
          </span>

          <lang-selector
            v-if="selectedLanguage"
            v-model:selected-language="selectedLanguage"
            :languages="allowLanguages"
          />
        </div>
      </layout-header>

      <layout-content class="content">
        <transition name="fade" mode="out-in">
          <error-view v-if="error" :error="error" />
          <complete-view v-else-if="isComplete" />

          <questions-view
            v-else-if="surveyModel"
            :questions="surveyModel.questions ?? []"
            :is-loading="isLoading"
            @update:answer="updateAnswer"
            @send="sendComplete"
            @progress="(value) => (progress = value)"
          />
          <question-skeleton v-else />
        </transition>
      </layout-content>
    </div>
  </layout>

  <spin v-if="isLoading && surveyModel" class="loader" size="large" spinning />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Layout, LayoutHeader, LayoutContent, Spin, Progress } from 'ant-design-vue';
import { useTheme } from '@/themes/hooks';
import LogoIcon from '@/components/icons/logo.vue';
import QuestionsView from '@/components/questions/Questions.vue';
import QuestionSkeleton from '@/components/questions/Question.skeleton.vue';
import CompleteView from '@/components/Complete.vue';
import ErrorView from '@/components/Error.vue';
import { useSurvey } from '@/hooks/survey';
import LangSelector from '@/components/LangSelector/LangSelector.vue';

const { theme, switchTheme } = useTheme();
switchTheme(window.location.hostname);
const themeStyles = computed(() => theme.value?.styles);

const {
  surveyModel,
  isLoading,
  error,
  progress,
  isComplete,
  updateAnswer,
  sendComplete,
  allowLanguages,
  selectedLanguage
} = useSurvey();
</script>

<style scoped lang="less">
@import '@/styles/breakpoints';

.root {
  background: var(--canvas-color);
}

.progress {
  line-height: 0;
  position: fixed;
  z-index: 1;

  & :deep(.ant-progress-inner) {
    border-radius: var(--progress-inner-radius);
    background: var(--progress-inner-color);
  }

  & :deep(.ant-progress-bg) {
    border-radius: var(--progress-bg-radius);
    background: var(--progress-bg-color);
    height: var(--progress-bg-width) !important;
  }
}

.container {
  display: flex;
  margin-top: var(--progress-bg-width);
}

.header {
  background: var(--header-color);
  width: 100%;
  height: var(--header-height);
  position: fixed;
  display: flex;
  padding: 0;
  z-index: 1;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    max-width: var(--question-item-width-1920);

    @media @size768 {
      max-width: var(--question-item-width-768);
    }

    margin: 0 auto;
    padding: var(--header-padding);
  }

  &-icon {
    display: flex;

    & > svg {
      width: 244px;
      height: 44px;
    }

    @media @size375 {
      & > svg {
        width: 162px;
        height: 29px;
      }
    }
  }
}

.content {
  margin-top: var(--header-height);
}

.loader {
  position: fixed;
  top: 50vh;
  left: 50vw;
}
</style>
