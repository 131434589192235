import { ThemeMap } from '@/themes/types';

export const themeMap: ThemeMap = new Map([
  ['localhost', 'zz'],
  ['n4.domain.ru', 'n4'],
  ['survey-dv.pax.websky.tech', 'dv'],
  ['survey-2s.pax.websky.tech', '2s'],
  ['survey-wz.pax.websky.tech', 'wz'],
  ['survey-yc.pax.websky.tech', 'yc'],
  ['survey-ji.pax.websky.tech', 'ji']
]);
