import { insertDomainPrefix } from '@/utils/common';

export const isDevMode = process.env.NODE_ENV !== 'production';

export const backUrl = process.env.VUE_APP_BACK_URL;
export const backApiUrl = process.env.VUE_APP_BACK_API_URL;
export const backDomainPrefix = process.env.VUE_APP_BACK_DOMAIN_PREFIX;

export const backFullUrl =
  (isDevMode ? '' : backUrl ? backUrl : insertDomainPrefix(window.origin, backDomainPrefix)) + backApiUrl;
