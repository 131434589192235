<template>
  <a-textarea :class="['textarea', themeStyles?.base?.textarea?.textarea]" :placeholder="placeholder">
    <slot />
  </a-textarea>
</template>

<script setup lang="ts">
import { Textarea as ATextarea } from 'ant-design-vue';
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

interface Props {
  placeholder?: string;
}
defineProps<Props>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
</script>

<style scoped lang="less">
.textarea {
  font-size: 19px;
  color: var(--primary-color);

  border: var(--textarea-border);

  &:hover,
  &:focus {
    border-color: var(--textarea-hovered-color);
  }

  &::placeholder {
    color: var(--textarea-placeholder-color);
  }
}
</style>
