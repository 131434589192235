import { ILogger } from '@/types';

export class ConsoleLogger implements ILogger {
  log(message: string, additional?: object): void {
    console.log(message, additional);
  }
  error(message: string, err?: Error): void {
    console.error(message, err);
  }
}
