<template>
  <radio-group
    v-model:value="innerValue"
    :class="[
      $attrs.class,
      'radio-answer',
      { 'has-custom': customOption },
      themeStyles?.answers?.radioAnswer?.radioAnswer
    ]"
  >
    <base-radio v-for="option in question.options" :key="option.value" :value="option.value">
      {{ option.label }}
    </base-radio>
  </radio-group>

  <base-textarea
    v-if="isCustomValue(innerValue)"
    :id="customValueElId"
    v-model:value="innerCustomValue"
    :class="['custom-value', themeStyles?.answers?.radioAnswer?.customValue]"
    :placeholder="t('textareaPlaceHolder')"
    @blur="onBlueTextarea"
  />
</template>

<script setup lang="ts">
import { RadioAnswerType, RadioQuestionRes } from '@/api/response.types';
import { computed, ref, watch, nextTick } from 'vue';
import { RadioGroup } from 'ant-design-vue';
import { BaseRadio, BaseTextarea } from '@/components';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

interface Props {
  question: RadioQuestionRes;
}

type ValueType = RadioAnswerType;

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:value', value: ValueType): void;
  (e: 'update:validation', valid: boolean): void;
}>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const { t } = useI18n();

const isCustomValue = (value: ValueType) =>
  value !== null && props.question.options.every((opt) => opt.value !== value || opt.custom);

const customOption = computed(() => {
  return props.question.options.find((opt) => opt.custom);
});
const customValueElId = `customValue[${props.question.id}]`;

const innerValue = ref<ValueType>(null);
const innerCustomValue = ref(
  (isCustomValue(props.question.answer) ? props.question.answer : customOption.value?.value) ?? ''
);

const getCustomNormalizeValue = () => {
  return innerCustomValue.value.trim();
};

const onBlueTextarea = () => {
  if (isCustomValue(innerValue.value)) {
    const newValue = getCustomNormalizeValue();

    if (newValue?.length && newValue !== props.question.answer) {
      emit('update:value', newValue);
    }
  }
};

watch(
  () => props.question.answer,
  () => {
    innerValue.value = isCustomValue(props.question.answer) ? customOption.value!.value : props.question.answer;
  },
  { immediate: true }
);
watch(innerValue, (value) => {
  let newValue = value;
  if (isCustomValue(value)) {
    newValue = getCustomNormalizeValue();

    nextTick(() => {
      document.getElementById(customValueElId)?.focus();
    });
  }

  if (newValue) {
    emit('update:value', newValue);
  }
});
watch(
  innerValue,
  (value) => {
    const validateValue = isCustomValue(value) ? getCustomNormalizeValue() : value;
    emit('update:validation', !!validateValue?.length);
  },
  { immediate: true }
);
watch(innerCustomValue, () => {
  emit('update:validation', !!getCustomNormalizeValue().length);
});
</script>

<style scoped lang="less">
.radio-answer {
  display: flex;
  gap: 32px;

  &.has-custom {
    flex-direction: column;
  }
}

.custom-value {
  min-height: 230px;
  margin: 10px 0 0 0;
}
</style>
