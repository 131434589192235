<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5803 11.0119L14.2902 8.72653V8.71654C13.8902 8.31735 13.8902 7.68861 14.2902 7.2994C14.6802 6.9002 15.3102 6.9002 15.7102 7.2994L19.7102 11.2914C19.9461 11.5207 20.0393 11.8449 19.9899 12.1515C19.9516 12.418 19.8066 12.6515 19.6 12.807L15.6995 16.6996C15.5095 16.8792 15.2495 16.989 14.9895 16.989L14.9995 17C14.7295 17 14.4695 16.8902 14.2895 16.7106C13.8895 16.3214 13.8895 15.6827 14.2795 15.2934V15.2835L16.5596 13.0079H5C4.44 13.0079 4 12.5588 4 12.0099C4 11.4511 4.44 11.0119 5 11.0119H16.5803Z"
      fill="currentColor"
    />
  </svg>
</template>
