import { QuestionRes } from '@/api/response.types';

export function questionsIsValid(questions: QuestionRes[]) {
  return questions.every((q) => {
    if (!q.answer) {
      return false;
    }

    //is not array
    if (!Array.isArray(q.answer)) {
      return !!q.answer.length;
    }

    //is array
    return q.answer.length && q.answer.every((v) => !!v);
  });
}
