export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface ILiteEvent<T> {
  on(handler: { (data: T): void }): void;
  off(handler: { (data: T): void }): void;
}

/**
 * Класс для использования событий
 */
export class LiteEvent<T> implements ILiteEvent<T> {
  private _handlers: { (data: T): void }[] = [];

  /**
   * Подписаться на событие
   */
  public on(handler: { (data: T): void }): void {
    this._handlers.push(handler);
  }

  /**
   * Отписаться от события
   * @param handler - обработчик события
   */
  public off(handler: { (data: T): void }): void {
    this._handlers = this._handlers.filter((h) => h !== handler);
  }

  /**
   * Вызвать событие
   * @param data - аргумент обработчика
   */
  public trigger(data: T) {
    this._handlers.slice(0).forEach((h) => h(data));
  }
}
