import { SurveyRes, SurveyStatusRes } from '@/api/response.types';
import { InitSurveyReq, SaveSurveyReq, CompleteReq } from '@/api/request.types';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import httpStatus from 'http-status-codes';
import { LiteEvent } from '@/types';
import { backFullUrl } from '@/config/config';

const fallbackLocale = ',en;q=0.9,mt;q=0.8';

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Accept-Language'] = 'ru' + fallbackLocale;
axios.defaults.baseURL = backFullUrl;

export class ApiClient {
  private readonly _axios = axios.create();

  readonly requestError: LiteEvent<string> = new LiteEvent();

  constructor() {
    this._axios.interceptors.response.use(
      (res) => {
        const data = res.data as SurveyStatusRes;
        if (!data?.success) {
          this.requestError.trigger(data.error ?? 'Error');
        }

        return res;
      },
      (err: AxiosError) => {
        const data = err.response?.data as SurveyStatusRes;
        this.requestError.trigger(data.message ?? err.message);
        return Promise.reject(err);
      }
    );
  }

  async initialize(survey: InitSurveyReq, lang: string) {
    const url = '/initialize';

    const config: AxiosRequestConfig<InitSurveyReq> = {
      headers: {
        ['Accept-Language']: lang + fallbackLocale
      }
    };

    let result: SurveyRes | undefined;

    try {
      const { data, status, headers } = await this._axios.post<SurveyRes>(url, survey, config);

      if (status === httpStatus.OK) {
        result = {
          ...data,
          lang: headers['content-language']
        };
      }
    } catch {
      result = undefined;
    }

    return result;
  }

  async saveAnswers(survey: SaveSurveyReq) {
    const url = '/save-answers';

    let result: SurveyStatusRes | undefined;

    try {
      const { data, status } = await this._axios.post<SurveyStatusRes>(url, survey);

      if (status === httpStatus.OK) {
        result = data;
      }
    } catch {
      result = undefined;
    }

    return result;
  }

  async complete(surveyId: string) {
    const url = '/complete';

    const reqData: CompleteReq = {
      id: surveyId
    };

    let result: SurveyStatusRes | undefined;
    try {
      const { data, status } = await this._axios.post<SurveyStatusRes>(url, reqData);

      if (status === httpStatus.OK) {
        result = data;
      }
    } catch {
      result = undefined;
    }

    return result;
  }
}
