import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import { logger } from '@/plugins';
import { Logger } from './services';
import { createHead, useHead } from '@vueuse/head';

import 'ant-design-vue/dist/antd.css';
import './styles/breakpoints.less';
import './styles/variables.less';
import './styles/app.css';

const app = createApp(App);

const i18n = createI18n({
  legacy: false,
  locale: navigator.language,
  fallbackLocale: 'en',
  messages: {
    en: {
      textareaPlaceHolder: 'Your answer'
    },
    ru: {
      textareaPlaceHolder: 'Ваш ответ'
    },
    hy: {
      textareaPlaceHolder: 'Ձեր պատասխանը'
    }
  }
});

const isDevMode = process.env.NODE_ENV !== 'production';

export const loggerService = Logger.init(app, router, isDevMode);

app.use(router).use(i18n).use(logger, loggerService).use(createHead());

app.directive('focus', {
  mounted(el) {
    el.focus();
  }
});

useHead({
  title: 'Survey'
});

app.mount('#app');
