import { App } from 'vue';
import { ILogger } from '@/types';
import { Plugin } from 'vue';

export const LOGGER = 'logger';

const logger: Plugin = {
  install: (app: App, logger: ILogger) => {
    app.config.globalProperties[LOGGER] = logger;

    app.provide(LOGGER, logger);
  }
};

export default logger;
