<template>
  <svg width="244" height="44" viewBox="0 0 244 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.7752 10.7301L26.6782 25.8745L22.613 10.7301H15.9752L11.9099 25.8745L7.8129 10.7301H0L7.24123 33.2378H15.6576L19.3099 19.701L22.9306 33.2378H31.3469L38.6199 10.7301H30.7752Z"
      fill="#3290FF"
    />
    <path
      d="M64.6572 28.061H49.9524V24.299H63.4503V19.0901H49.9524V15.939H64.3396V10.7301H42.4253V33.2378H64.6572V28.061Z"
      fill="#3290FF"
    />
    <path
      d="M90.9873 21.4051V21.373C93.3692 20.6013 94.8302 19.1544 94.8302 16.5178C94.8302 11.9198 91.2413 10.7301 85.9057 10.7301H69.74V33.2378H85.2387C92.1941 33.2378 95.656 31.7908 95.656 26.6141C95.656 23.6881 93.8456 22.1447 90.9873 21.4051ZM85.1435 15.939C87.0173 15.939 87.4937 16.6785 87.4937 17.7074C87.4937 18.7364 87.0173 19.5724 85.1435 19.5724H77.267V15.939H85.1435ZM85.5246 28.061H77.267V24.1704H85.5246C87.4937 24.1704 88.0336 24.9099 88.0336 25.971C88.0336 27.1607 87.5572 28.061 85.5246 28.061Z"
      fill="#3290FF"
    />
    <path
      d="M114.06 19.1865C110.249 18.8007 107.486 18.6721 107.486 17.1287C107.486 15.8425 109.074 15.3924 112.123 15.3924C115.013 15.3924 116.792 16.0676 116.792 17.611H124.446C124.446 13.7204 121.651 10.2799 112.218 10.2799C103.58 10.2799 99.9907 12.9165 99.9907 17.4824C99.9907 21.9839 102.404 23.4308 109.233 24.4919C115.616 25.4887 117.776 25.3922 117.776 26.9034C117.776 28.1574 115.998 28.6076 113.012 28.6076C109.9 28.6076 107.359 28.0288 107.359 25.9388H99.4825C99.4825 30.6654 103.198 33.7201 112.599 33.7201C121.333 33.7201 125.875 31.5014 125.875 26.0996C125.875 20.4084 121.016 19.9261 114.06 19.1865Z"
      fill="#3290FF"
    />
    <path
      d="M147.729 33.2378H156.526L146.395 19.4116L156.081 10.7301H146.776L137.851 18.9614V10.7301H130.324V33.2378H137.851V27.0321L141.281 23.9453L147.729 33.2378Z"
      fill="#3290FF"
    />
    <path
      d="M177.142 10.7301L171.426 19.701L165.741 10.7301H157.388L167.678 25.2636V33.2378H175.205V25.2636L185.495 10.7301H177.142Z"
      fill="#3290FF"
    />
    <path
      d="M213.375 44L227.758 26.562C228.456 25.7373 229.362 25.0679 230.403 24.6069C231.444 24.1458 232.592 23.9058 233.757 23.9058H243.97L229.647 41.3172C228.954 42.1466 228.049 42.8194 227.007 43.2809C225.964 43.7424 224.814 43.9797 223.648 43.9734L213.375 44ZM244 20.1871H233.787C232.622 20.1871 231.474 19.9471 230.433 19.4861C229.392 19.025 228.486 18.3557 227.788 17.5309L213.375 0H223.648C224.813 0 225.961 0.240022 227.002 0.701057C228.043 1.16209 228.949 1.83148 229.647 2.6562L244 20.1871ZM203.882 34.3845L211.381 25.2074C211.745 24.7725 212.22 24.4196 212.768 24.1774C213.315 23.9351 213.918 23.8103 214.53 23.8129H221.639L214.14 32.9768C213.78 33.4165 213.306 33.7736 212.758 34.0185C212.21 34.2633 211.604 34.3888 210.991 34.3845H203.882ZM221.684 20.1871H214.65C214.037 20.1904 213.431 20.0644 212.884 19.8196C212.336 19.5748 211.862 19.2183 211.501 18.7794L204.002 9.61545H211.111C211.724 9.61116 212.33 9.73666 212.878 9.98152C213.426 10.2264 213.9 10.5835 214.26 11.0232L221.684 20.1871Z"
      fill="#3290FF"
    />
  </svg>
</template>
