import { useRoute } from 'vue-router';
import { AnswerReq, InitSurveyReq } from '@/api/request.types';
import { ApiClient } from '@/api/client';
import { onUnmounted, reactive, ref, watch } from 'vue';
import { AnswerType, QuestionRes, SurveyRes } from '@/api/response.types';
import { questionsIsValid } from '@/utils/questions';
import { useI18n } from 'vue-i18n';
import { Language } from '@/types';
import { languages } from '@/consts/languages';

type AnswerValueType = AnswerType;

const MAX_ERROR_COUNT = 3;

export function useSurvey() {
  const { locale } = useI18n();

  const route = useRoute();

  const apiClient = new ApiClient();

  const surveyModel = ref<Omit<SurveyRes, 'allowedLocales' | 'lang'>>();
  const isComplete = ref(false);
  const isLoading = ref(false);
  const progress = ref(0);
  const error = ref<string>();
  const errorCount = ref(0);

  const preferredLang = (route.query.lang as string) ?? navigator.language;

  const allowLanguages = ref<Language[]>([]);
  const selectedLanguage = ref<Language>();

  const updateError = (isError: boolean, errMsg: string) => {
    if (isError) {
      errorCount.value++;

      if (errorCount.value > MAX_ERROR_COUNT) {
        error.value = errMsg;
      }
    } else {
      errorCount.value = 0;
    }
  };

  const onRequestError = (err: string) => {
    error.value = err;
  };

  apiClient.requestError.on(onRequestError);
  onUnmounted(() => {
    apiClient.requestError.off(onRequestError);
  });

  function init(preferredLang: string) {
    const preAnswers: AnswerReq[] = [];
    if (route.params.questionId && route.params.answer) {
      preAnswers.push({
        questionId: route.params.questionId as string,
        answer: route.params.answer as string
      });
    }

    const initSurvey: InitSurveyReq = {
      key: route.params.surveyKey as string,
      answers: preAnswers
    };

    isLoading.value = true;

    apiClient.initialize(initSurvey, preferredLang).then(async (res) => {
      if (res) {
        const { lang, allowedLocales, ...resData } = res;

        const accepedLang = lang.split(',')[0].trim();

        allowLanguages.value = allowedLocales.map((locale) => ({
          label: languages[locale],
          locale
        }));

        const newLang = allowLanguages.value.find((i) => i.locale === accepedLang);
        if (selectedLanguage.value !== newLang) {
          selectedLanguage.value = allowLanguages.value.find((i) => i.locale === accepedLang);
        }

        surveyModel.value = {
          ...resData,
          questions: resData.questions.map((q) => reactive(q))
        };

        locale.value = accepedLang;
      }
      isLoading.value = false;
    });
  }

  init(preferredLang);

  const updateAnswer = async (question: QuestionRes, value: AnswerValueType) => {
    if (!surveyModel.value) {
      return;
    }

    question.answer = value;

    isLoading.value = true;
    const response = await apiClient.saveAnswers({
      id: surveyModel.value.id,
      answers: surveyModel
        .value!.questions.filter((question) => question.answer !== null)
        .map((question) => ({ questionId: question.id, answer: question.answer }))
    });

    updateError(!response || !response.success, response?.error ?? '');

    isLoading.value = false;
  };

  const sendComplete = async () => {
    if (!surveyModel.value) {
      return;
    }

    isLoading.value = true;
    const response = await apiClient.complete(surveyModel.value.id);
    if (response?.success) {
      isComplete.value = true;
      progress.value = 100;
    }
    isLoading.value = false;
  };

  watch(surveyModel, () => {
    if (!surveyModel.value) {
      return;
    }

    if (questionsIsValid(surveyModel.value.questions)) {
      isComplete.value = true;
    }
  });

  watch(selectedLanguage, (newLang, oldLang) => {
    if (oldLang && newLang?.locale !== oldLang?.locale) {
      init(newLang!.locale);
    }
  });

  return {
    surveyModel,
    isLoading,
    error,
    progress,
    isComplete,
    selectedLanguage,
    allowLanguages,
    updateAnswer,
    sendComplete
  };
}
