<template>
  <div :class="['complete']">
    <div :class="['complete__content']">
      <div :class="['complete__content__header']">
        <span :class="['complete__content__header__title']">{{ t('Thank you!') }}</span>
        <span :class="['complete__content__header__description']">{{ t('You helped us a lot') }}</span>
      </div>

      <div :class="['complete__content__img']">
        <template v-if="theme?.images?.complete">
          <component :is="theme.images.complete" />
        </template>
        <template v-else>
          <complete-img />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CompleteImg from '@/components/images/Complete.vue';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

const { theme } = useTheme();
const { t } = useI18n();
</script>

<style scoped lang="less">
@import '@/styles/breakpoints';

.complete {
  display: flex;
  align-items: center;
  background: var(--question-item-bg-color);
  min-height: calc(100vh - var(--progress-bg-width) - var(--header-height));

  &__content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: var(--header-padding);
    max-width: var(--question-item-width-1920);

    @media @size375, @size768 {
      align-items: flex-start;
      flex-direction: column;
      max-width: 707px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 0;
      white-space: nowrap;

      &__title {
        font-size: 41px;
        font-weight: 600;
        color: var(--complete-title-color);
      }

      &__description {
        font-size: 23px;
      }
    }

    &__img {
      display: flex;

      & > svg {
        width: 100%;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "Thank you!": "Thank you!",
    "You helped us a lot": "You helped us a lot"
  },
  "ru": {
    "Thank you!": "Спасибо!",
    "You helped us a lot": "Вы нам очень помогли"
  },
  "hy": {
    "Thank you!": "Շնորհակալություն",
    "You helped us a lot": "Դուք մեզ շատ օգնեցիք"
  }
}
</i18n>
