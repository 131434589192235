import { ILogger } from '@/types';
import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { Breadcrumb } from '@sentry/types';

export class SentryLogger implements ILogger {
  private static _instance: SentryLogger;

  private constructor(app: App, router: Router) {
    Sentry.init({
      app,
      dsn: 'https://ffe6ca651575453e99e1b11b7ca2f90e@o279119.ingest.sentry.io/4504479197298688',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', /^\//]
        })
      ],
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV
    });
  }

  log(message: string, additional?: Omit<Breadcrumb, 'message'>): void {
    Sentry.addBreadcrumb({
      message,
      level: 'info',
      ...additional
    });
  }

  error(message: string): void {
    Sentry.captureException(message);
  }

  static init(app: App, router: Router) {
    if (!SentryLogger._instance) {
      SentryLogger._instance = new SentryLogger(app, router);
    }

    return SentryLogger._instance;
  }
}
