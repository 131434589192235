import { ILogger } from '@/types';
import { App } from 'vue';
import { Router } from 'vue-router';
import { ConsoleLogger } from '@/services/loggers/ConsoleLogger';
import { SentryLogger } from '@/services/loggers/Sentry';

export class Logger {
  private static _instance: ILogger;

  static init(app: App, router: Router, isDevMode: boolean) {
    if (Logger._instance) {
      throw new Error('Logger already init');
    }

    Logger._instance = isDevMode ? new ConsoleLogger() : SentryLogger.init(app, router);

    return Logger._instance;
  }

  static getInstance() {
    return Logger._instance;
  }
}
