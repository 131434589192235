import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import SurveyView from '@/views/SurveyView.vue';
import NotFound from '@/views/NotFoundView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Survey',
    path: '/survey/:surveyKey/:questionId?/:answer?',
    component: SurveyView
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
