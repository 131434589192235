<template>
  <router-view />
</template>

<script setup lang="ts">
import { provide, ref } from 'vue';
import { THEME_KEY } from '@/consts/common';
import { Theme } from '@/themes/types';

provide(THEME_KEY, ref<Theme>());
</script>
