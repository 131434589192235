<template>
  <ant-button
    :disabled="disabled"
    :type="type"
    :class="['button', { primary: type === 'primary' }, { disabled: disabled }, themeStyles?.base?.button?.button]"
  >
    <template v-if="$slots.leftIcon" #icon>
      <slot name="leftIcon"></slot>
    </template>

    <div :class="['button-content', themeStyles?.base?.button?.content]">
      <slot />
      <slot name="rightIcon" />
    </div>
  </ant-button>
</template>

<script setup lang="ts">
import { computed, VNode } from 'vue';
import { Button as AntButton } from 'ant-design-vue';
import { useTheme } from '@/themes/hooks';
import { ButtonType } from 'ant-design-vue/lib/button';

interface Props {
  leftIcon?: VNode;
  rightIcon?: VNode;
  disabled?: boolean;
  type?: ButtonType;
}

defineProps<Props>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
</script>

<style scoped lang="less">
.button {
  padding: 16px 23px;
  height: auto;
  border: var(--button-border);
  border-radius: var(--button-border-radius);
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  background: var(--button-background);

  &.disabled {
    border: var(--disabledButton-border);
    cursor: not-allowed;

    & .button-content {
      color: var(--disabledButton-color);
    }
  }

  &:hover,
  &:focus {
    border: var(--button-hovered-border);
  }

  &.primary {
    background: var(--primaryButton-background);
    border: var(--primaryButton-border);

    & .button-content {
      color: var(--primaryButton-color);
    }

    &.disabled {
      background: var(--primaryDisabledButton-background);

      &:hover {
      }
    }
  }

  &-content {
    display: flex;
    gap: 16px;
    align-items: center;
    color: var(--button-color);
  }
}
</style>
