export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
        "You helped us a lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You helped us a lot"])}
      },
      "ru": {
        "Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо!"])},
        "You helped us a lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы нам очень помогли"])}
      },
      "hy": {
        "Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շնորհակալություն"])},
        "You helped us a lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դուք մեզ շատ օգնեցիք"])}
      }
    }
  })
}
